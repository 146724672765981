import { useRef } from 'react';
import emailjs from '@emailjs/browser';

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKInput from 'components/MKInput';
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';

// Images
import bgImage from 'assets/images/examples/blog2.jpg';
// import { ReCAPTCHA } from "react-google-recaptcha";
// import { Button } from "@mui/material";

function Contact() {
    const form = useRef();
    const captchaRef = useRef(null);

    // const ReCAPCHA_SITE_KEY = "6Ld_mTQmAAAAAFvUmt5bizt41Pi3JXr4IY5KkkhH";
    // const ReCAPCHA_SECRET_KEY = "6Ld_mTQmAAAAAC4yOK45X6tyDO6pEaeGoWETUAtY";

    // const [SuccessMsg, setSuccessMsg] = useState("");
    // const [ErrorMsg, setErrorMsg] = useState("");
    // const [valid_token, setValidToken] = useState([]);

    const sendEmail = async (e) => {
        e.preventDefault();

        const token = captchaRef.current.getValue();
        console.log('captcha token: ', token);
        captchaRef.current.reset();

        // if (token) {
        //   let valid_token = await verifyToken(token);
        //   setValidToken(valid_token);

        //   if (valid_token[0].success === true) {
        //     console.log("verified");
        //     setSuccessMsg("Hurray!! you have submitted the form");
        //   } else {
        //     console.log("not verified");
        //     setErrorMsg(" Sorry!! Verify you are not a bot");
        //   }
        // }

        emailjs
            .sendForm('service_5hykeue', 'template_brdwqxa', form.current, 'gird8MAhTwKXSlNRO')
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
    };

    return (
        <MKBox component='section' py={{ xs: 0, lg: 6 }}>
            <Container>
                <Grid container item>
                    <MKBox
                        width='100%'
                        bgColor='white'
                        borderRadius='xl'
                        shadow='xl'
                        mb={6}
                        sx={{ overflow: 'hidden' }}
                    >
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                position='relative'
                                px={0}
                                sx={{
                                    backgroundImage: ({
                                        palette: { gradients },
                                        functions: { rgba, linearGradient },
                                    }) =>
                                        `${linearGradient(
                                            rgba(gradients.dark.main, 0.8),
                                            rgba(gradients.dark.state, 0.8)
                                        )}, url(${bgImage})`,
                                    backgroundSize: 'cover',
                                }}
                            >
                                <MKBox
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    width='100%'
                                    height='100%'
                                >
                                    <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my='auto'>
                                        <MKTypography variant='h3' color='white' mb={1}>
                                            Contact Information
                                        </MKTypography>
                                        <MKTypography
                                            variant='body2'
                                            color='white'
                                            opacity={0.8}
                                            mb={3}
                                        >
                                            Fill up the form and our Team will get back to you
                                            within 24 hours.
                                        </MKTypography>
                                        <MKBox display='flex' p={1}>
                                            <MKTypography variant='button' color='white'>
                                                <i className='fas fa-phone' />
                                            </MKTypography>
                                            <MKTypography
                                                component='span'
                                                variant='button'
                                                color='white'
                                                opacity={0.8}
                                                ml={2}
                                                fontWeight='regular'
                                            >
                                                +1 (613) 209-3841
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox display='flex' color='white' p={1}>
                                            <MKTypography variant='button' color='white'>
                                                <i className='fas fa-envelope' />
                                            </MKTypography>
                                            <MKTypography
                                                component='span'
                                                variant='button'
                                                color='white'
                                                opacity={0.8}
                                                ml={2}
                                                fontWeight='regular'
                                            >
                                                info@dwijatech.ca
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox display='flex' color='white' p={1}>
                                            <MKTypography variant='button' color='white'>
                                                <i className='fas fa-map-marker-alt' />
                                            </MKTypography>
                                            <MKTypography
                                                component='span'
                                                variant='button'
                                                color='white'
                                                opacity={0.8}
                                                ml={2}
                                                fontWeight='regular'
                                            >
                                                Ottawa, ON
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox mt={3}>
                                            <MKButton
                                                variant='text'
                                                color='white'
                                                size='large'
                                                iconOnly
                                            >
                                                <i
                                                    className='fab fa-facebook'
                                                    style={{ fontSize: '1.25rem' }}
                                                />
                                            </MKButton>
                                            <MKButton
                                                variant='text'
                                                color='white'
                                                size='large'
                                                iconOnly
                                            >
                                                <i
                                                    className='fab fa-twitter'
                                                    style={{ fontSize: '1.25rem' }}
                                                />
                                            </MKButton>
                                            <MKButton
                                                variant='text'
                                                color='white'
                                                size='large'
                                                iconOnly
                                            >
                                                <i
                                                    className='fab fa-dribbble'
                                                    style={{ fontSize: '1.25rem' }}
                                                />
                                            </MKButton>
                                            <MKButton
                                                variant='text'
                                                color='white'
                                                size='large'
                                                iconOnly
                                            >
                                                <i
                                                    className='fab fa-instagram'
                                                    style={{ fontSize: '1.25rem' }}
                                                />
                                            </MKButton>
                                        </MKBox>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <MKBox
                                    id='emailForm'
                                    component='form'
                                    ref={form}
                                    p={2}
                                    method='post'
                                    // onSubmit={sendEmail}
                                >
                                    <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                                        <MKTypography variant='h2' mb={1}>
                                            Say Hi!
                                        </MKTypography>
                                        <MKTypography variant='body1' color='text' mb={2}>
                                            We&apos;d like to talk with you.
                                        </MKTypography>
                                    </MKBox>
                                    <MKBox pt={0.5} pb={3} px={3}>
                                        <Grid container>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    id='user_name'
                                                    name='user_name'
                                                    variant='standard'
                                                    label='Full Name'
                                                    // placeholder="Name"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    id='user_email'
                                                    name='user_email'
                                                    variant='standard'
                                                    label='Email Id:'
                                                    // placeholder="Email"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    id='subject'
                                                    name='subject'
                                                    variant='standard'
                                                    label='Subject'
                                                    // placeholder="Title"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    id='message'
                                                    name='message'
                                                    variant='standard'
                                                    label='Your Message'
                                                    // placeholder="Message"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    multiline
                                                    rows={6}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                justifyContent='flex-start'
                                                ml='auto'
                                            >
                                                <MKInput
                                                    name='subject'
                                                    variant='standard'
                                                    label='Subject'
                                                    // placeholder="Title"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={6}
                                                justifyContent='flex-end'
                                                textAlign='right'
                                                ml='auto'
                                            >
                                                <MKButton
                                                    type='submit'
                                                    variant='gradient'
                                                    color='info'
                                                    form='emailForm'
                                                >
                                                    Send Message
                                                </MKButton>
                                            </Grid>
                                        </Grid>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                        </Grid>
                    </MKBox>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Contact;
