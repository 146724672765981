/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

// Material Kit 2 React examples
import DefaultCounterCard from 'examples/Cards/CounterCards/DefaultCounterCard';

// Images
// import coinbase from "assets/images/logos/gray-logos/logo-coinbase.svg";
// import nasa from "assets/images/logos/gray-logos/logo-nasa.svg";
// import netflix from "assets/images/logos/gray-logos/logo-netflix.svg";
// import pinterest from "assets/images/logos/gray-logos/logo-pinterest.svg";
// import spotify from "assets/images/logos/gray-logos/logo-spotify.svg";
// import vodafone from "assets/images/logos/gray-logos/logo-vodafone.svg";
import gopal from 'assets/images/clients/gopal.png';
import gokul from 'assets/images/clients/gokul.png';
import banasDairy from 'assets/images/clients/banasDairy.png';
import ramdev from 'assets/images/clients/ramdev.webp';
import archean from 'assets/images/clients/archean.png';
import jkTrading from 'assets/images/clients/jkTrading.png';

// import bgImage from "assets/images/bg-presentation.jpg";

function Featuring() {
    return (
        <MKBox
            component='section'
            variant='gradient'
            bgColor='light'
            position='relative'
            py={6}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
        >
            <Container>
                {/* <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2" mb={6}>
            Our Valuable Clients
          </MKTypography>
        </Grid> */}
                {/* <Grid container spacing={3} sx={{ mb: 12 }}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={gopal} alt="Gopal" width="100%" height="120px" />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={gokul} alt="Gokul" width="100%" height="100px" />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={ramdev} alt="Ramdev" width="100%" height="100px" />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={banasDairy} alt="Banas Dairy" width="100%" height="100px" />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={archean} alt="Archean" width="100%" height="100px" />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={jkTrading} alt="J K Trading" width="100%" height="100px" />
          </Grid>
        </Grid> */}
                <Grid container justifyContent='center' sx={{ textAlign: 'center' }}>
                    <Grid item xs={12} md={3}>
                        <DefaultCounterCard
                            count={15}
                            separator=','
                            title='Projects'
                            description='Of “high-performing” level are led by a certified team members'
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DefaultCounterCard
                            count={3400}
                            separator=','
                            suffix='+'
                            title='Hours'
                            description='That meets quality standards required by our clients'
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DefaultCounterCard
                            count={24}
                            suffix='/7'
                            title='Support'
                            description='Actively engage team members that finishes on time'
                        />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Featuring;
