/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

// Material Kit 2 React components
import TransparentBlogCard from 'examples/Cards/BlogCards/TransparentBlogCard';
// import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from 'assets/images/what-we-offer/Flow-Solution.jpeg';
import post2 from 'assets/images/what-we-offer/supply-chain.jpeg';
import post3 from 'assets/images/what-we-offer/system-optimization.jpeg';
import post4 from 'assets/images/what-we-offer/maintenance-support.jpeg';

function Places() {
    return (
        <MKBox component='section' py={2}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={8}
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    sx={{ mx: 'auto', textAlign: 'center' }}
                >
                    <MKTypography variant='h2' mb={6}>
                        What We Offer
                    </MKTypography>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <TransparentBlogCard
                            image={post1}
                            title='Requirement Gathering'
                            description='understanding your needs and capturing detailed specifications to deliver tailored IT solutions that align perfectly with your business objectives'
                            // action={{
                            //   type: "internal",
                            //   route: "/pages/blogs/author",
                            //   color: "info",
                            //   label: "read more",
                            // }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <TransparentBlogCard
                            image={post2}
                            title='Product Design'
                            description='Transforming your vision into a blueprint, creating intuitive, user-centric designs that drive engagement and enhance user experience'
                            // action={{
                            //   type: "internal",
                            //   route: "/pages/blogs/author",
                            //   color: "info",
                            //   label: "read more",
                            // }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <TransparentBlogCard
                            image={post3}
                            title='Product Development'
                            description='Turning concepts into reality, leveraging cutting-edge technologies to build robust, scalable IT solutions tailored to your business needs'
                            // action={{
                            //   type: "internal",
                            //   route: "/pages/blogs/author",
                            //   color: "info",
                            //   label: "read more",
                            // }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <TransparentBlogCard
                            image={post4}
                            title='Maintenance and Support'
                            description='Providing ongoing assistance, ensuring your IT systems remain efficient, secure, and up-to-date, minimizing downtime and maximizing performance'
                            // action={{
                            //   type: "internal",
                            //   route: "/pages/blogs/author",
                            //   color: "info",
                            //   label: "read more",
                            // }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post4}
              title="Flexible work hours"
              description="Rather than worrying about switching offices every couple years, you stay in the same place."
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                label: "read more",
              }}
            />
          </Grid> */}
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Places;
