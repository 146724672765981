/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
// import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
// import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";
import Posts from 'pages/LandingPages/AboutUs/sections/Posts';
// import Team from "pages/LandingPages/AboutUs/sections/Team";
import Featuring from 'pages/LandingPages/AboutUs/sections/Featuring';
// import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import routes from 'routes';
import footerRoutes from 'footer.routes';

// Images
import bgImage from 'assets/images/bg-about-us.jpeg';
import Contact from 'pages/LandingPages/AboutUs/sections/Contact';
import Footer from './sections/Footer';

function AboutUs() {
    return (
        <>
            <DefaultNavbar routes={routes} transparent light />
            <MKBox
                minHeight='75vh'
                width='100%'
                sx={{
                    backgroundImage: ({
                        functions: { linearGradient, rgba },
                        palette: { gradients },
                    }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.8),
                            rgba(gradients.dark.state, 0.8)
                        )}, url(${bgImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'grid',
                    placeItems: 'center',
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'
                        sx={{ mx: 'auto', textAlign: 'center' }}
                    >
                        <MKTypography
                            variant='h1'
                            color='white'
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down('md')]: {
                                    fontSize: size['3xl'],
                                },
                            })}
                        >
                            Welcome to Dwija Technolabs
                        </MKTypography>
                        <MKTypography variant='body1' color='white' mt={1} mb={3}>
                            Empowering Innovation Through Technology
                        </MKTypography>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                {/* <Information /> */}
                <Posts />
                {/* <Team /> */}
                <Featuring />
                <Contact />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <Footer content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default AboutUs;
